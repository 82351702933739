// Main styles

@import "~Sass/donate/page-progress-bar";

body {
	padding: 0 0 1rem;

	& > .container-md {
		padding-top: 1rem;
	}
}

@include media-breakpoint-down(sm) {
	body, body > .container-md {
		padding: 0;
		border-radius: 0;
	}
	.container-md > .box {
		border-radius: 0;
		overflow: hidden; // Due to above padding removal causing issues
	}
}

header {
	text-align: left;
	@include media-breakpoint-down(sm) {
		text-align: center;
	}
}

// Charity logos are displayed on the edge of the container box
.has-charity-logos {
	header {
		.logos {
			justify-content: center;
			flex-wrap: nowrap;

			.col-auto {
				flex-shrink: 1;

				&:not(:first-child) {
					padding-left: 0;
				}
			}
		}

		.logo {
			margin: 0 0 2rem;
		}
	}
}

@include media-breakpoint-up(sm) {
	.has-charity-logos {
		margin-top: 45px;

		header {
			.logo {
				margin: -2rem 0 -.5rem;
				transform: translateY(-50%);
			}
		}
	}
}

// GAYL logo in header
.brand-logo {
	width: auto;
	display: block;
	max-width: 80%;
	margin: 15px auto;
	max-height: 60px;
}

// Text header
.donation-header {
	padding: 2rem;
	text-align: center;
}

// Charity logo in header
.charity-logo {
	width: auto;
	top: 1.5rem;
	left: 1.5rem;
	position: absolute;
	border-radius: .5rem;
	box-shadow: $box-shadow;
	max-height: calc(100% - 3rem);

	@include media-breakpoint-down(xs) {
		left: 50%;
		top: .75rem;
		transform: translateX(-50%);
		max-height: calc(100% - 1.5rem);
	}
}

// Remove bottom margin from last element in each section
.box__content > :last-child:not(.btn-set) {
	margin-bottom: 0;
}

// Set amounts - prevents breaking onto 2 lines on smallest screens
.js-set-amount-group.restrict {
	height: 3.25rem;
	overflow: hidden;
}

// Single set amount
.js-set-amount {
	margin-bottom: 1rem;
}

// Table alignment, used for donation reasons table
.table td { vertical-align: middle; }

// Button toggles - dont want the active state colour change, messes with the tick icon colour
// div[data-toggle="buttons"] .active.btn-navy {
.btn-navy:not(:disabled):not(.disabled):active, .btn-navy:not(:disabled):not(.disabled).active {
  border-color: $navy;
  background-color: $navy;
}

// Remove focus state from button-checkboxes
.btn .form-check-input:focus ~ .form-check-label::before {
	box-shadow: none;
}

// Thank you screen

.gayl-promo {
	img {
		width: 100%;
	}
}

// Footer
footer {
	// Don't know why the #{} is needed here, but it wasn't compiling properly
	--link-color: #{$body-color};
	--link-hover-color: #{$body-color}; // Don't know

	padding: 15px 0;
	font-size: .75rem;
	text-align: center;
	color: var(--body-color);

	.brand-logo {
		margin: 0 auto 1rem 0;
		display: inline-block;
	}
}

.grecaptcha-badge {
	margin: 0 auto;
}

// Address dropdown will overflow
.box {
	z-index: 2;
	overflow: unset;
}
.list-box, .list-box > .box__content {
	&:first-child {
		border-top-left-radius: .5rem;
		border-top-right-radius: .5rem;
	}
	&:last-child {
		border-bottom-left-radius: .5rem;
		border-bottom-right-radius: .5rem;
	}
}

// Toast on ticketing purchase form

.timer-toast {
	right: 1rem;
	bottom: 1rem;
	width: 30rem;
	position: fixed;
	z-index: $zindex-sticky;
	max-width: calc(100% - 2rem);
}

// Overide for Zendesk button if the timer is showing to stop them overlapping
body:has(.js-basket-timer.show) {
	#launcher {
			bottom: 5rem !important;
			bottom: calc(5rem + env(safe-area-inset-bottom)) !important; // Special, not supported by all browsers
		}
}


// Tap to Donate overrides - disable animations and make modal backdrop darker

.disable-animations {
	.collapsing {
		transition: none !important;
	}
}

.dark-modal-backdrop {
	.modal-backdrop {
		--backdrop-opacity: 0.9;
	}
}