// Errors (mostly stolen from Bootstrap alerts)

$error-background: shift-color($red, $alert-bg-scale);
$error-border: shift-color($red, $alert-border-scale);
$error-color: shift-color($red, $alert-color-scale);

.parsley-error,
.parsley-error input:not([type=radio],[type=checkbox]),
.parsley-error .input-group-text {
	border-color: $red;	
}

.parsley-error {
	.form-control,
	.form-select {
		box-shadow: 0 0 0 $form-select-focus-width rgba($red, .25);
	}
}

ul.parsley-errors-list {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		color: $error-color;
		margin: 1rem 0;
		border: 1px solid $error-border;
		background-color: $error-background;
		padding: $alert-padding-y $alert-padding-x;
		@include border-radius($alert-border-radius);
	}

	li:hover {
		background: $error-background; // because `.list-box li` is doing stuff
	}

	a {
		text-decoration: underline;
		color: shade-color($error-color, 20%);;
	}
}